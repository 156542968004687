import slugify from "slugify";

export function obj_to_slug(id, qualified_name) {
    return slugify(`${id}-${qualified_name}`);
}

export function slug_to_id(slug) {
    const dash_idx = slug.indexOf('-')
    if (dash_idx === -1)
        return parseInt(slug)
    return parseInt(slug.substring(0, dash_idx));
}

export function max_string(str, max_len){
    if(str.length <= max_len)
        return str
    return str.substring(0, max_len) + '...'
}
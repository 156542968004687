import {toRaw} from "vue";

const brand_context_tools = [
    {
        name: 'Landscape Keywords',
        key: 'keywords',
        url: {name: 'keywords', params: {brand_context_slug: 'null'}}
    },
    {
        name: 'Tracking Pixels',
        key: 'pixels',
        url: {name: 'pixels', params: {brand_context_slug: 'null'}}
    },
    {
        name: 'Influencer Campaigns',
        key: 'influencer_campaigns',
        url: {name: 'influencer_campaigns', params: {brand_context_slug: 'null'}}
    },
];

const context_free_tools = [
    {
        name: 'Brands',
        key: 'brands',
        url: {name: 'brand_view'}
    },
];

const admin_tools = [
    {
        name: 'Users',
        key: 'users',
        url: {name: 'user'}
    },
    {
        name: 'Service Monitoring',
        key: 'service_monitoring',
        url: {name: 'service_monitoring'}
    },
    {
        name: 'Chart Test',
        key: 'chart_test',
        url: {name: 'charts'}
    }
];

const state = () => ({
    brand_context_tools,
    context_free_tools,
    admin_tools
})

const actions = {}

const mutations = {}

const getters = {
    user_brand_context_tools(state, getters, rootState) {
        return state.brand_context_tools.filter(t => rootState.auth.data.permissions.tools.includes(t.key)).map(t => {
            const x = toRaw(t);
            x.url.params.brand_context_slug = (rootState.brands.brand_context && rootState.brands.brand_context.slug) ?? 'null';
            return x;
        })
    },
    user_context_free_tools(state, getters, rootState) {
        return state.context_free_tools.filter(t => rootState.auth.data.permissions.tools.includes(t.key))
    },
    user_admin_tools(state, getters, rootState) {
        return state.admin_tools.filter(t => rootState.auth.data.permissions.tools.includes(t.key))
    },
    all_tools(state, getters, rootState){
        return rootState.auth.data.permissions.tools
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}